import { useAtomValue } from "jotai";
import { ReactNode, useMemo } from "react";

import { clientConfigAtom } from "@/utils/atoms";

type Props = {
  children: ReactNode;
};

export function ThemeProvider({ children }: Props) {
  const clientConfig = useAtomValue(clientConfigAtom);
  const themeName = useMemo(
    () => clientConfig?.themeColor ?? "default",
    [clientConfig],
  );

  return (
    <div
      style={{ height: "100dvh", overflow: "hidden" }}
      data-theme={themeName}
    >
      {children}
    </div>
  );
}
