import { AiOutlineClose } from "react-icons/ai";
import styled, { css, keyframes } from "styled-components";

const fadeIn = keyframes`
  from {
    opacity: 0;
    transform: translateY(100%);
  }
  to {
    opacity: 1;
    transform: translateY(0%);
  }
`;

export const AssistantWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !["isMobile", "showChat"].includes(prop),
})<{ isMobile: boolean; showChat: boolean }>`
  position: relative;
  width: 100%;
  height: 100dvh;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  animation: ${fadeIn} 0.5s ease-in-out;

  ${({ isMobile, showChat }: { isMobile: boolean; showChat: boolean }) =>
    isMobile
      ? css`
          height: ${showChat ? "auto" : "100dvh"};
        `
      : ""}
`;

export const BackgroundWrapper = styled.div<{ src: string }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: ${(props) => `url(${props.src})`};
  background-size: cover;
  background-position: center;
`;

export const LogoWrapper = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  background: none;
  border: none;
  padding: 0;
  z-index: 999;
`;

export const AssistantIconWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    !["isMobile", "showChat", "hasAssistantBanner"].includes(prop),
})<{ isMobile: boolean; showChat: boolean; hasAssistantBanner: boolean }>`
  width: 100%;
  height: calc(90% - 60px);

  ${({ isMobile, showChat }: { isMobile: boolean; showChat: boolean }) =>
    showChat
      ? css`
          height: ${isMobile ? "calc(90% - 60px)" : "calc(100% - 60px)"};
          ${isMobile ? "margin-top: 0px;" : ""}
        `
      : css`
          position: ${isMobile ? "absolute" : "relative"};
          top: ${isMobile ? "20%" : ""};
          height: ${isMobile ? "calc(100% - 60px)" : "calc(90% - 60px)"};
          ${isMobile ? "" : "margin-top: 20%;"}
        `}

  transition: height 0.01s ease-in-out;

  ${({
    isMobile,
    hasAssistantBanner,
  }: {
    isMobile: boolean;
    hasAssistantBanner: boolean;
  }) =>
    hasAssistantBanner && !isMobile
      ? css`
          position: absolute;
          bottom: 3.5rem;
        `
      : ""}
`;

export const StartButtonWrapper = styled.div`
  position: fixed;

  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  width: 100dvw;
  height: 100dvh;

  background: rgba(0, 0, 0, 0.3);
`;

export const StartButton = styled.button`
  display: flex;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 700;
  line-height: 1.5;

  border: none;
  color: #fff;
  width: 140px;
  height: 140px;
  border-radius: 50%;
  cursor: pointer;
  background: #02B1DC;
  box-shadow: 0 7px 0 #0096B0;
  transition: all 0.3s ease;
  @media (hover: hover) {
    &:hover {
      transform: translate(0, 4px);
      color: #fff;
      box-shadow: 0 3px 0 #0096B0;
    }
  }
  @media (hover: none) {
    &:active {
      transform: translate(0, 4px);
      color: #fff;
      box-shadow: 0 3px 0 #0096B0;
    }
`;

export const CommentImage = styled.img`
  width: 24px;
  height: 24px;
`;

export const CommentText = styled.span`
  margin-top: 4px;
  margin-bottom: 16px;
`;

export const CommentArrow = styled.img`
  width: 16px;
  height: 16px;
`;

export const ChatAreaWrapper = styled.div`
  padding: 5rem 1.25rem;
  background-color: hsla(0, 0%, 100%, 0.5);
  width: -webkit-fill-available;
  height: -webkit-fill-available;
  overflow-y: scroll;
  position: fixed;
  top: 0;
  left: 0;
`;

export const CloseButton = styled(AiOutlineClose)`
  position: fixed;
  top: 1rem;
  right: 1rem;
  font-size: 1.5rem;
  border: 1px solid #000;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  padding: 0.25rem;
  cursor: pointer;
`;

export const ChatLogWrapper = styled.div<{ role: string }>`
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: ${(props) =>
    props.role === "user" ? "flex-end" : "flex-start"};
`;

export const ChatLogContents = styled.div<{ role: string }>`
  display: flex;
  flex-direction: column;
  align-items: ${(props) =>
    props.role === "user" ? "flex-end" : "flex-start"};
  width: fit-content;
`;

export const ChatLogContent = styled.div<{ role: string }>`
  padding: 1rem;
  background: ${(props) =>
    props.role === "user" ? "var(--button-background)" : "#fff"};

  color: ${(props) => (props.role === "user" ? "#fff" : "#000")};
  border-radius: ${(props) =>
    props.role === "user" ? "25px 25px 0px 25px" : "25px 25px 25px 0"};
`;

export const AssistantCommentWrapper = styled.div.withConfig({
  shouldForwardProp: (prop) =>
    !["isMobile", "hasAssistantBanner", "hasBannerLink"].includes(prop),
})<{ isMobile: boolean; hasAssistantBanner: boolean; hasBannerLink: boolean }>`
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: ${(props) => {
    if (!props.isMobile && props.hasAssistantBanner && props.hasBannerLink)
      return "7rem";
    return "2rem";
  }};

  max-width: 28rem;
  max-height: calc(100dvh - 100px);
`;

export const AssistantComment = styled.div`
  background: #fff;
  color: #000;
  border-radius: 25px 25px 25px 0;
  padding: 1rem;
  margin: 1rem;
  cursor: pointer;
  justify-content: left;
  overflow-y: scroll;

  @media (hover: hover) {
    &:hover {
      box-shadow: 0px 3px 2px 2px rgba(0, 0, 0, 0.2);
    }
  }

  @media (hover: none) {
    &:active {
      box-shadow: 0px 3px 2px 2px rgba(0, 0, 0, 0.2);
    }
  }
`;

const ellipsisAnimation = keyframes`
  0% {
    content: "";
  }
  25% {
    content: "・";
  }
  50% {
    content: "・・";
  }
  75% {
    content: "・・・";
  }
  100% {
    content: "";
  }
`;

export const CommentLoading = styled.p`
  width: 40px;
  display: inline-block;
  &:after {
    content: "・・・";
    animation: ${ellipsisAnimation} 1s infinite;
  }

  justify-content: left;
`;

export const UserSelectButtonsWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
`;

export const UserSelectButton = styled("button").withConfig({
  shouldForwardProp: (prop) => !["isButtonVisible"].includes(prop),
})<{ isButtonVisible: boolean }>`
  background: var(--button-background);
  border: none;
  color: #fff;
  border-radius: 100px;
  padding: 8px 12px;
  margin: 0.25rem 0.5rem;
  cursor: pointer;
  min-width: 70px;
  display: ${(props) => (props.isButtonVisible ? "block" : "none")};
  font-size: 12px !important;

  @media (hover: hover) {
    &:hover {
      box-shadow: 0px 1px 2px 2px rgba(0, 0, 0, 0.2);
    }
  }

  @media (hover: none) {
    &:active {
      box-shadow: 0px 1px 2px 2px rgba(0, 0, 0, 0.2);
    }
  }
`;

export const UserActionWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 2rem 1rem 0;
  width: -webkit-fill-available;

  .textInputWidth {
    flex-grow: 1;
  }

  .buttonSendMargin {
    margin: 0 0 0 12px;
  }

  .buttonMicroPhoneMargin {
    margin: 0 0 0 8px;
  }
`;

export const AssistantStartButton = styled.button`
  background: var(--button-background);
  border: none;
  color: #fff;
  border-radius: 100px;
  padding: 16px 24px;
  margin: 0.5rem 1rem;
  cursor: pointer;
  min-width: 140px;
  font-size: 24px !important;

  @media (hover: hover) {
    &:hover {
      box-shadow: 0px 2px 4px 4px rgba(0, 0, 0, 0.2);
    }
  }

  @media (hover: none) {
    &:active {
      box-shadow: 0px 2px 4px 4px rgba(0, 0, 0, 0.2);
    }
  }
`;

export const AssistantEndButtonWrapper = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem;
  max-width: 28rem;
  max-height: calc(100dvh - 100px);
`;

export const AssistantEndButton = styled.button`
  background: var(--button-background);
  border: none;
  color: #fff;
  border-radius: 100px;
  padding: 16px 24px;
  margin: 0.5rem 1rem;
  cursor: pointer;
  min-width: 140px;
  font-size: 24px !important;

  @media (hover: hover) {
    &:hover {
      box-shadow: 0px 2px 4px 4px rgba(0, 0, 0, 0.2);
    }
  }

  @media (hover: none) {
    &:active {
      box-shadow: 0px 2px 4px 4px rgba(0, 0, 0, 0.2);
    }
  }
`;
