import { useEffect, useRef } from "react";

import { AssistantChatArea } from "@/commands/AssistantOnly/chatAreaComponent";
import { useMiibo } from "@/commands/AssistantOnly/useMiibo";

type Props = {
  isChatLogExpanded: boolean;
  setIsChatLogExpanded: (val: boolean) => void;
  clientId: string;
  isMobile: boolean;
  hasAssistantBanner: boolean;
  hasBannerLink: boolean;
};

export function AssistantNormalMode({
  isChatLogExpanded,
  setIsChatLogExpanded,
  clientId,
  isMobile,
  hasAssistantBanner,
  hasBannerLink,
}: Props) {
  const miiboHooks = useMiibo({
    code: clientId,
  });

  const isStartedMiibo = useRef(false);
  useEffect(() => {
    if (!isStartedMiibo.current) {
      miiboHooks.startMiibo();
      isStartedMiibo.current = true;
    }
  }, [miiboHooks]);

  return (
    <AssistantChatArea
      setIsChatLogExpanded={setIsChatLogExpanded}
      isChatLogExpanded={isChatLogExpanded}
      miiboHooks={miiboHooks}
      isMobile={isMobile}
      hasAssistantBanner={hasAssistantBanner}
      hasBannerLink={hasBannerLink}
    />
  );
}
