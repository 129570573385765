import { useAtomValue } from "jotai";
import { useMemo, useState } from "react";

import {
  AssistantWrapper,
  AssistantIconWrapper,
  StartButtonWrapper,
  StartButton,
  CommentImage,
  CommentText,
  CommentArrow,
} from "@/commands/AssistantOnly/index.style";
import { AssistantNormalMode } from "@/commands/AssistantOnly/normalMode";
import { RecoCharacter } from "@/components/RecoCharacter";
import { FrameState } from "@/hooks/useFrameControl";
import ArrowSvg from "@/public/arrow.svg";
import CommentSvg from "@/public/comment.svg";
import { clientConfigAtom } from "@/utils/atoms";

type Props = {
  setFrameState: React.Dispatch<React.SetStateAction<FrameState | undefined>>;
  imageLoaded: boolean;
  handleImageLoaded: () => void;
  isMobile: boolean;
};

export default function AssistantOnly({
  setFrameState,
  imageLoaded,
  handleImageLoaded,
  isMobile,
}: Props) {
  const [showChat, setShowChat] = useState(false);
  const [isChatLogExpanded, setIsChatLogExpanded] = useState<boolean>(false);
  const client = useAtomValue(clientConfigAtom);
  const assistantBannerSrc = useMemo(
    () => client.assistantBanner || "",
    [client],
  );

  const handleShowChat = () => {
    setShowChat(!showChat);

    if (isMobile) {
      setFrameState({
        style: {
          right: 0,
          bottom: 0,
          width: "100%",
          height: "calc(100dvh - 20%)",
        },
      });
    } else {
      setFrameState({
        style: {
          right: 0,
          bottom: 0,
          width: "50%",
          height: "calc(100dvh - 10%)",
          transform: "translateX(-50%)",
        },
      });
    }
  };

  const bannerLink = useMemo(() => client.bannerLink || "", [client]);

  return (
    <AssistantWrapper isMobile={isMobile} showChat={showChat}>
      {!showChat && (
        <StartButtonWrapper>
          <StartButton onClick={handleShowChat}>
            <CommentImage src={CommentSvg} alt="" />
            <CommentText>
              AIキャラと
              <br />
              会話を始める
            </CommentText>
            <CommentArrow src={ArrowSvg} alt="" />
          </StartButton>
        </StartButtonWrapper>
      )}

      <AssistantIconWrapper
        isMobile={isMobile}
        showChat={showChat}
        hasAssistantBanner={Boolean(assistantBannerSrc)}
      >
        <RecoCharacter
          handleImageLoad={handleImageLoaded}
          imageLoaded={imageLoaded}
          selectedWidth={isMobile ? "20rem" : "35rem"}
        />
      </AssistantIconWrapper>

      {showChat && (
        <AssistantNormalMode
          setIsChatLogExpanded={setIsChatLogExpanded}
          isChatLogExpanded={isChatLogExpanded}
          clientId={client.id}
          isMobile={isMobile}
          hasAssistantBanner={Boolean(assistantBannerSrc)}
          hasBannerLink={Boolean(bannerLink)}
        />
      )}
    </AssistantWrapper>
  );
}
